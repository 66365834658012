<template>
	<div class="dashboard-container">
		<BatchExport v-if="dialog.refresh2" :dialog.sync="dialog.show2" @get-list="getList" @refresh="refreshDialog" />
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" :gutter="20">
                <el-col :span="5">
<!--				<el-form label-width="70px" class="allForm">-->
<!--					<el-form-item label="搜索：">-->
						<el-input  size="mini" v-model="searchText" clearable style="width: 300px;" class="formInput"
                                   placeholder="请输入" @input="changeCondition" />
<!--						<vxe-button style="margin-left: 10px;" @click="changeCondition">搜索</vxe-button>-->
<!--					</el-form-item>-->
<!--				</el-form>-->
                </el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="space-between">
							<el-col>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:add'
                                            )
                                        "
								 type="primary" size="mini" @click="openDialog({title:'添加试题',addOr:'add'})">添加试题</el-button>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:batch:import'
                                            )
                                        "
								 type="warning" size="mini" @click="openExport">批量导入</el-button>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:batch:delete'
                                            )
                                        "
								 type="danger" size="mini" @click="removeAll({del:'batch'})">批量删除</el-button>
							</el-col>
							<el-col :span="3">
								<vxe-button v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:export'
                                            )
                                        "
								 @click="exportTableAll">导出excel</vxe-button>
								<vxe-button @click="changeCondition">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="580" row-id="company_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="100" fixed="left" />
					<vxe-table-column type="seq" width="100" />
					<vxe-table-column field="mentalTypeName" title="量表类型" />
					<vxe-table-column field="topicTitle" title="量表标题" />
					<vxe-table-column field="statu" title="量表选项" width="300">
						<template v-slot="{row}">
							<div v-for="(item,index) in row.mentalAssessmentTopicOptionVos" :key="index">
								<div v-if="index != (row.mentalAssessmentTopicOptionVos.length - 1)" style="border-bottom: 1px solid #DCDCDC;">{{item.content}}</div>
								<div v-if="index == (row.mentalAssessmentTopicOptionVos.length - 1)">{{item.content}}</div>
							</div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="statu" title="选项分值" width="300">
						<template v-slot="{row}">
							<div v-for="(item,index) in row.mentalAssessmentTopicOptionVos" :key="index">
								<div v-if="index != (row.mentalAssessmentTopicOptionVos.length - 1)" style="border-bottom: 1px solid #DCDCDC;">{{item.score}}</div>
								<div v-if="index == (row.mentalAssessmentTopicOptionVos.length - 1)">{{item.score}}</div>
							</div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:edit'
                                            )
                                        "
									 icon="el-icon-edit" @click.native="openDialog({title:'修改试题',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:evaluationScale:delete'
                                            )
                                        "
									 icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from "@/utils/handleByte";
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		company
	} from '@/api'
	import BatchExport from './BatchExport'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			BatchExport,
			Operate
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshUser: false, // 每次刷新DOM
					refreshIns: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showUser: false,
					showIns: false
				},
				searchText: '',
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			// 打开批量导入
			openExport() {
				this.dialog.refresh2 = true
				this.dialog.show2 = true
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic', null, {
					topicTitle: this.searchText,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			removeAll() {
				const id = []
				const arr = this.$refs.table.getCheckboxRecords()
				for (const i of arr) {
					id.push(i.id)
				}
				if (!id || id.length < 1) {
					this.$message.info('请选择需要删除的对象！')
					return false
				}
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic', id, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.dialog.refresh2 = false
				this.dialog.refresh3 = false
				this.dialog.refreshUser = false
				this.dialog.refreshIns = false
				this.getList()
			},
			exportTableAll() {
				this.loading = true;
				this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic/export', null, {
						topicTitle: this.searchText,
						currentPage: this.table.currentPage,
						pageSize: this.table.pageSize,

					}, "get")
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
		}
	}
</script>

<style scoped>

</style>
