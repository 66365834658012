<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div v-if="!userShowList" style="padding-right: 25px;">
			<div slot="title" class="header-title">
				<el-row :gutter="5">
					<el-col :span="24">
						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
					</el-col>
				</el-row>
			</div>
			<div style="width: 100%;height: 20px;"></div>
			<el-row :gutter="0">
				<el-col :span="24">
					<el-form label-width="110px" class="allForm">
						<el-form-item label="量表类型：" :required="true">
							<el-cascader :options="options" :props="optionProps" @change="changeOptions" :placeholder="optionsText"
							 :show-all-levels="false"></el-cascader>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-form label-width="110px" class="allForm">
						<el-form-item label="量表标题：" :required="true">
							<el-input type="textarea" show-word-limit v-model="topicTitle" class="formInput" placeholder="请输入内容" />
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-form label-width="110px" class="allForm">
						<el-form-item label="量表信息：" :required="true">
							<div class="pb-20" v-for="(item,index) in textList">
								<el-row>
									<el-col :span="10">
										<el-input v-model="item.content" class="formInput" placeholder="请输入量表选项" />
									</el-col>
									<el-col :span="4">
										<div class="text-center">-</div>
									</el-col>
									<el-col :span="10">
										<el-input type="number" v-model="item.score" class="formInput" placeholder="请输入选项分值" />
									</el-col>
								</el-row>
							</div>
							<el-button type="info" icon="el-icon-plus" @click="addList"></el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				optionsId: '',
				optionsText: '请选择',
				options: [],
				optionProps: {
					value: 'id',
					label: 'mentalTypeName',
					children: 'mentalAssessmentDimensionVos',
					checkStrictly: true
				},
				show: this.dialog,
				topicTitle: '',
				textList: [{
					content: '',
					score: '',
					topicOptions: 'A',
				},{
					content: '',
					score: '',
					topicOptions: 'B',
				}],
				userShowList: false,
				typeList: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N'],
				typeIndex: 2
			}
		},
		created() {},
		mounted() {
			this.mentalAssessmentType()
			this.upd()
		},
		methods: {
			mentalAssessmentType() {
				this.loading = true
				this.$axiosReq('/course/server/escort/web/mentalAssessmentType', null, {
					currentPage: 1,
					pageSize: 1000,
				}, 'get').then(res => {
					this.options = res.data.content;
				}).finally(e => {
					this.loading = false
				})
			},
			upd() {
				if (this.info.addOr === 'upd') {
					this.optionsId = this.row.mentalTypeId;
					this.optionsText = this.row.mentalTypeName;
					this.textList = this.row.mentalAssessmentTopicOptionVos;
					this.topicTitle = this.row.topicTitle;
					this.typeIndex = this.row.mentalAssessmentTopicOptionVos.length;
				}
			},
			// 提交添加
			commit() {
				if (this.info.addOr === 'add') {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic', {
						"mentalAssessmentTopicOptionDtos": this.textList,
						"mentalTypeId": this.optionsId,
						"topicTitle": this.topicTitle
					}, null, 'post').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				} else {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentTopic/' + this.row.id, {
						"mentalAssessmentTopicOptionDtos": this.textList,
						"mentalTypeId": this.optionsId,
						"topicTitle": this.topicTitle
					}, null, 'put').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			addList(i) {
				if (this.typeIndex > 8) {
					// this.$message.error('最多添加10条');
					return false;
				}
				this.textList.push({
					content: '',
					score: '',
					topicOptions: this.typeList[this.typeIndex],
				});
				this.typeIndex++
			},
			changeOptions(v) {
				let idLength = v.length - 1;
				this.optionsId = v[idLength];
			}
		}
	}
</script>

<style scoped>
	.input-with-select {
		width: 280px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}
</style>
